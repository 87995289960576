import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function MarketMaker({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('MarketMaker');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>Market Maker</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default MarketMaker;

const DATA = [
    {
        title: 'Will anybody be able to tell that I\'m using a bot?',
        content: 'No. Volume comes from random generated wallets over which you hold full control'
    },
    {
        title: 'What networks does the bot work on?',
        content: 'ETH, BSC, AVAX, MATIC, METIS, etc. --- Any EVM compatible network. Refer this <a href="/">link</a> for available blockchain'
    },
    {
        title: 'How many funds do I need to provide for market making?',
        content: 'It depends on the volume that you want to achieve and the average network fees. You are responsible for providing the bot with enough money for the gas fees to work.'
    },
    {
        title: 'What if the network gas price suddenly increases?',
        content: `You can configure the amount you are willing to pay in gas for each transaction. If the gas price is too high, the ransaction won't execute. MM will automatically go back to work when the gas price goes below your limit`
    },
    {
        title: 'I still don\'t understand how random intervals work',
        content: 'Say you want to execute a transaction every 1 hour. Even if it comes from different wallets, having a transaction every hour at :00 might look odd. FiBot will execute one transaction at a random time within that hour. For example, one transaction will be at 9:14 and the next one at 10:37'
    },
    {
        title: 'Can I make a volume of 1 million$?',
        content: 'Did the market start to heat up on its own? That\'s great news! You can request to pause the bot and resume the remaining period later.'
    },
    {
        title: 'What is the pause feature?',
        content: 'Did the market start to heat up on its own? That\'s great news! You can request to pause the bot and resume the remaining period later'
    },
    {
        title: 'Is it possible to maintain the rating and trust at CoinMarketCap and CoinGecko using Market Maker?',
        content: 'Yes, with the correct settings and when the conditions of the rating services are met, you will be able to maintain good ratings and trust.'
    }
]